.App {
  height: 100%;
  background-color: var(--light-background);
}

p, h2, h3 {
  padding: 10px;
  margin: 10px;
}

h3 {
  font-size: 1.5em;
  color: var(--dark-text);
}

li {
  list-style-type: none;
}

.offscreen {
  position: relative;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  margin-top: -1px;
}

/* landing page */

.landing {
  text-align: center;
  width: 100%;
  height: 100%;
}

.landing_text {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  border-radius: 10px;
  left: 50%;
  padding: 10vmin;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #292929;
}

.landing_name {
  padding: 20px 20px 0px 20px;
  font-weight: 700;
}

.landing_title {
  padding: 10px;
  margin-top: 0px;
  font-weight: 500;
}

.landing_button {
  width: 180px;
  border: solid 3px #292929;
  margin: auto;
  padding: 15px 10px;
  color: #292929;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_arrow {
  height: 15px;
  padding-left: 10px;
  position: relative;
}

/* navbar */

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  grid-area: nav;
  background-color: var(--dark-background);
  padding: 20px;
  outline: none;
}

.nav_button {
  background-color: var(--dark-background);
  color: var(--light-text);
  padding: 10px;
  font-size: 1em;
  border: solid 2px transparent;
  margin: 3px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  } 100% {
    top: -3px;
    transform: rotate(90deg);
  }
}

@keyframes spin-speaking {
  0% {
    transform: rotate(0deg);
  } 100% {
    top: 3px;
    transform: rotate(90deg);
  }
}

/* base container styling */

.main-container {
  background-color: var(--light-background);
  display: grid;
  grid-template-columns: .05fr 1fr .05fr;
  grid-template-rows: .25fr auto;
  grid-template-areas:
  "nav nav nav"
  ". blog ."
  ". speaking ."
  ". work ."
  ". about ."
  ". contact .";
  padding-bottom: 2%;
}

.error-container {
  background-color: var(--light-background);
  display: grid;
  grid-template-columns: .05fr 1fr .05fr;
  grid-template-rows: .25fr auto;
  grid-template-areas:
  "nav nav nav"
  ". error .";
  padding-bottom: 2%;
}

.container_about {
  grid-area: about;
}

.container_contact {
  grid-area: contact;
}

.flex-container {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.section_title {
  margin-top: 20px;
  font-size: 2em;
  font-weight: bold;
  grid-area: sectionTitle;
  color: var(--light-text);
}

.secondary-container {
  color: var(--dark-text);
  background: var(--accent);
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  border-radius: 10px;
}

/* content */

.container_content {
  grid-area: blog;
}

.content-content {
  width: 100%;
  margin: 0;
  padding: 4%;
}

.content-graphic {
  padding: 0px 20px 0px 80px;
  max-width: 300px;
}

.content_link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content_link p {
  margin-right: 0;
  padding-top: 0;
}

.content_link a {
  margin: 0 0 10px 0;
}

/* speaking */

.container_speaking {
  grid-area: speaking
}

.talks h3 {
  margin: 0;
}

.talks {
  padding: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.talk-list {
  padding: 5px;
}

.talk-list li {
  padding: 5px;
}

.talk-list li p {
  padding: 0;
  margin: 5px;
}

.speaking_CTA {
  display: block;
  width: 230px;
  border: solid 3px #292929;
  margin: 5%;
  padding: 10px 10px;
  color: #292929;
  background: rgba(255, 255, 255, 0.1);
}

.speaking_CTA .button_arrow {
  padding-left: 0;
  top: 2px;
}

.talk_link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.talk_link svg {
  margin-bottom: 3px
}

.event_title {
  font-size: 1.25em;
}

/* work */

.work_image {
  max-height: 200px;
  max-width: 200px;
  margin: 10px;
  grid-area: pic;
}

.container_work {
  display: grid;
  place-items: center;
  grid-template-areas: 
  "sectionTitle"
  "digitalGarden"
  "thisSite"
  "gachasphere"
  "bujo"
  "knitworthy"
  "OWS";
  grid-template-columns: 1fr;
  row-gap: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  grid-area: work;
}

#digitalGarden {
  grid-area: digitalGarden;
}

#this {
  grid-area: thisSite;
}

#gachasphere {
  grid-area: gachasphere;
}

#BujoToGo {
  grid-area: bujo;
}

#knitworthy {
  grid-area: knitworthy;
}

#OWS {
  grid-area: OWS;
}

.work {
  display: grid;
  grid-template-areas: 
    "title"
    "type"
    "pic"
    "icons"
    "text";
  grid-template-rows: .1fr .1fr .1fr .1fr 1fr;
  place-items: center;
  color: var(--dark-text);
  background: var(--accent);
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  border-radius: 10px;
}

.work_this {
  display: grid;
  grid-template-areas: 
    "title"
    "type"
    "icons"
    "text";
  grid-template-rows: .1fr .1fr .1fr 1fr;
  place-items: center;
  color: var(--dark-text);
  background: var(--accent);
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  border-radius: 10px;
}

.work_title--primary {
  grid-area: title;
}

.work_title--secondary {
  grid-area: type;
}

.work_text {
  grid-area: text;
}

.work_links {
  grid-area: icons;
}

.work_container_image {
  grid-area: pic;
  display: flex;
  justify-content: space-around;
}

.work_container_image > img {
  max-height: 200px;
  max-width: 200px;
  margin: 5px;
  padding: 5px;
}

.work_icon {
  width: 50px;
  margin: 10px;
}

#gachaGif {
  border-radius: 10px;
}

/* about */

.about {
  background: var(--accent);
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  border-radius: 10px;
  padding: 20px;
}

.skills-icon {
  width: 60px;
  margin: 20px;
}

.container_skills-icon {
  background: rgba(255, 255, 255, .55);
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  border-radius: 10px;
  margin: 0 5%;
}

.about_headshot {
  width: 150px;
  border-radius: 10px;
}

/* contact */

.contact {
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  border-radius: 10px;
  background: var(--accent);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: var(--light-text);
}

.contact_text {
  margin: 10px auto;
  padding: 5px;
  color: var(--dark-text);
}

.contact_list li {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-logo {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.linked-in {
  width: 35px;
}

.tiktok {
  border-radius: 4px;
}

.contact-form {
  background: rgba(255, 255, 255, .55);
  box-shadow: 0 8px 32px 0 rgba(0,0,0,.37);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 30px;
}

.contact-form p {
  margin: 10px;
  padding: 0px;
  color: var(--dark-text);
}

.contact-form input, .message_input {
  margin: 10px;
}

.message, .contact_email, .contact_name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message_input {
  padding: 30px 0px;
}

.contact_button {
  background: none;
  color: var(--dark-text);
  padding: 10px;
  font-size: 1em;
  border: solid 2px transparent;
  margin: 3px;
}

.contact_p {
  padding-top: 0;
  margin-top: 0;
  color: var(--dark-text);
}

/* button animations */

button:hover, button:focus, button:active {
  outline: none;
}

.landing_button:hover, .landing_button:focus, .speaking_CTA:hover, .speaking_CTA:focus, .speaking_CTA:active {
  background-color: #292929;
  color: #F9F8F8;
}

.landing_button:hover .button_arrow, .landing_button:focus .button_arrow, .speaking_CTA:hover .button_arrow, .speaking_CTA:focus .button_arrow {
  stroke: #F9F8F8;
  animation-name: spin;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  transition-delay: 0ms;
  transition: all 200ms ease-in;
}

/* react transition group animations */

.landing-enter, .navBar-enter, .mainFull-enter, .single-enter {
  opacity: 0.01;
}

.landing-enter-active, .navBar-enter-active, .mainFull-enter-active, .single-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.landing-exit, .navBar-exit, .mainFull-exit, .single-exit {
  opacity: 1;
}

.landing-exit-active, .navBar-exit-active, .mainFull-exit-active, .single-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* links */

.external-link {
  height: 1em;
  width: 1em;
  top: .15em;
  position: relative;
}

.download-file {
  height: 1em;
  width: 1em;
  top: .2em;
  position: relative;
}

a:not(.nav_button, .speaking_CTA, .landing_button) {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a:link:not(.nav_button, .speaking_CTA, .landing_button), a:visited:not(.nav_button, .speaking_CTA, .landing_button) {
  color: var(--dark-text);
}

a:hover, a:focus, a:active {
  color: var(--light-background);
}

.speaking_CTA:hover .button_arrow, .speaking_CTA:focus .button_arrow {
  animation-name: spin-speaking;
}

.nav_button:hover, .nav_button:focus, .nav_button:active {
  color: var(--light-text);
  border: solid 2px var(--button-border);
}

.contact_button:hover, .contact_button:focus, .contact_button:active {
  cursor: pointer;
  border: solid 2px var(--dark-text);
}

.speaking_CTA, .nav_button, .landing_button {
  text-decoration: none;
}

/* media queries */

@media screen and (min-width: 350px) {

  .about_headshot {
    width: 300px;
  }

  .work_container_image > img, .work_image {
    max-width: 300px;
  }

  .contact-form {
    min-width: 300px;
  }

  .blog-content, .talks {
    padding: 5%;
  }

  .speaking_CTA {
    margin: 5%;
  }
}

@media screen and (min-width: 550px) {
  nav {
    flex-direction: row;
  }
}

@media screen and (min-width: 700px) {

  .work_container_image > img, .work_image {
    max-width: 200px;
    max-height: 200px;
  }

  .work {
    grid-template-areas: 
    "title text"
    "type text"
    "pic text"
    "icons text";
    grid-template-rows: 1fr;
    grid-template-columns: .25fr 1fr;
  }

  .work_this {
    grid-template-areas: 
    "title text"
    "type text"
    "icons text";
    grid-template-rows: .5fr .1fr 1fr;
    grid-template-columns: .25fr 1fr;
  }

  .work_icon {
    padding-bottom: 20px;
  }

  .contact-form {
    min-width: 500px;
    padding: 15px;
  }

  .message, .contact_name, .contact_email {
    flex-direction: row;
  }

  .blog-content, .talks {
    padding: 3%;
  }
  
  .speaking_CTA {
    margin: 3%;
  }

  .content_link {
    flex-direction: row;
  }
}

@media screen and (min-width: 800px) {
  .work_container_image > img, .work_image {
    max-width: 300px;
  }
}

@media screen and (min-width: 900px) {
  .work_container_image > img, .work_image {
    max-width: 400px;
  }
}